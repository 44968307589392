import React, { Component } from 'react';
import Appbar from '../../component/header/appbar';
import './../../App.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
// import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import SendIcon from '@material-ui/icons/Send';
const styles = ({
    divBox: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
    },
    statusDiv: {
        height: '55px',
        width: '100%',
        border: '1px solid',
        borderColor: '#064E5D',
        margin: 20,
        borderRadius: 5,
    },
    nameDiv: {
        height: '180px',
        width: '100%',
        border: '1px solid',
        borderColor: '#064E5D',
        margin: 20,
        borderRadius: 5,
    },
    activity: {
        height: '190px',
        width: '100%',
        border: '1px solid',
        borderColor: '#064E5D',
        margin: 20,
        borderRadius: 5,
    },
    statusInfo: {
        display: 'flex',
        flexDirection: 'row',
        paddingLeft: 10,
        paddingTop: 5,
    },
    padding_Left: {
        // paddingLeft: 40,
    },
    textWidth: {
        width: '150px'
    },
    buttonMargin: {
        marginLeft: 5,
    },
    mainDiv: {
        display: 'flex',
        flexDirection: 'row',
    },
    info: {
        width: '70%'
    },
    chat: {
        width: "30%"
    },
    chat_container: {
        height: '100%',
        background: '#d3d3d3',
        padding: 10,
        marginRight: 5,

    },
    chat_input: {
        display: 'flex',
        background: 'lightgray',
        position: 'fixed',
        bottom: '8px',
        padding: '5px',
        paddingLeft: '3px',
        paddingRight: '8px',

    },
    chat_send_btn: {
        paddingLeft: '109px',
        paddingTop: '2px',
    },
    container: {
        marginTop: 65,
    }
});
class Positiondetails extends Component {
    render() {
        const { classes } = this.props;

        return (
            <div>
                <Appbar name={'name'} />
                <div className={classes.container}>
                    <div className={classes.mainDiv}>
                        <div className={classes.info}>
                            <div className={classes.divBox}>
                                <div className={classes.statusDiv}>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Position Name:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            Hello user
                                    </Typography>
                                    </div>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Campaing Status:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            Active
                                    </Typography>
                                    </div>
                                </div>
                                <div className={classes.statusDiv}>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Mandatory KSAs:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            100%
                                    </Typography>
                                    </div>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Prequalified:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            Yes
                                    </Typography>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.divBox}>
                                <div className={classes.nameDiv}>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            First Name:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            Hello user
                                    </Typography>
                                    </div>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Last Status:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            Active
                                    </Typography>
                                    </div>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Preferred Communication:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            Text
                                    </Typography>
                                    </div>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Email:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            baghelsuraj567@gmail.com
                                    </Typography>
                                    </div>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Phone Number:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            +91-94246233555
                                    </Typography>
                                    </div>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Match Date:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            Aug 6, 2019
                                    </Typography>
                                    </div>
                                </div>
                                <div className={classes.nameDiv}>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Employer Status:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            Evaluated
                                    </Typography>
                                    </div>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Review Status:
                                    </Typography>
                                        <Typography variant="subtitle2" className={classes.padding_Left}>
                                            Accepted
                                    </Typography>
                                    </div>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Hiring Process Status:
                                    </Typography>
                                        <div>
                                            <Button variant="contained" size="small" className="primary-button">
                                                Active
                                        </Button>
                                            <Button variant="contained" size="small" className={classes.buttonMargin}>
                                                Inactive
                                        </Button>
                                        </div>

                                    </div>
                                    <div className={classes.statusInfo}>
                                        <Typography variant="subtitle2" className={classes.textWidth}>
                                            Hiring Stage:
                                    </Typography>
                                        <div>
                                            <select>
                                                <option value="all">All</option>
                                                <option value="Accepted">Accepted</option>
                                                <option value="Contacted">Contacted</option>
                                                <option value="PendingDocs">Pending Docs</option>
                                                <option value="DocsReceived">Docs Received</option>
                                                <option value="InRoute">In Route</option>
                                                <option value="InterviewScheduled">Interview Scheduled</option>
                                                <option value="InterviewCompleted">Interview Completed</option>
                                                <option value="Offered">Offered</option>
                                                <option value="Hired">Hired</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={classes.divBox}>
                                <div className={classes.activity}>
                                    <Typography variant="subtitle1" style={{ paddingLeft: 10 }} >
                                        Notes & Comments
                                </Typography>
                                </div>
                            </div>
                        </div>
                        <div className={classes.chat}>
                            <div className={classes.chat_container}>
                                {/* chat */}
                                hjhjhj


                            </div>
                            <div className={classes.chat_input}>
                                <div>
                                    <input style={{
                                        padding: 10,
                                        marginTop: '8px',

                                        borderRadius: 30, border: 'none', width: '174%'
                                    }}
                                        placeholder="Your Message"
                                        margin="normal"
                                    />
                                </div>
                                <div className={classes.chat_send_btn}>
                                    <IconButton aria-label="Delete" className={classes.margin}>
                                        <SendIcon />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
Positiondetails.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Positiondetails);
