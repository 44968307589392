export function positionListData() {
    let data = JSON.parse(sessionStorage.getItem('userData'))
    // console.log(data.userData);
    let positionlistData = {
        "user_id": data.userData.user_id,
        "token": data.userData.token,
        "client_code": data.userData.client_code
    }
    let BaseURL = 'https://api.bighustledigital.com/index.php/positionlist';
    return new Promise((resolve, reject) => {
        fetch(BaseURL, {
            method: 'POST',
            body: JSON.stringify(positionlistData)
        })
            .then((response) => response.json())
            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}