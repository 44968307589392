export function PostData(userData) {
    console.log(userData);
    
    let BaseURL = 'https://api.bighustledigital.com/index.php/login';
    return new Promise((resolve, reject) => {
        fetch(BaseURL, {
            method: 'POST',
            body: JSON.stringify(userData)
        })
            .then((response) => response.json())

            .then((res) => {
                resolve(res);
            })
            .catch((error) => {
                reject(error);
            });
    });
}