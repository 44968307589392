import React, { Component } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
// import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import BackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIos from '@material-ui/icons/ArrowForwardIos'
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/styles';
import { positionListData } from '../../services/positionList';
const styles = ({
    root: {
        flexGrow: 1,
    },
    title: {
        flexGrow: 1,
    },
    rewired_sidemenu: {
        height: '100%',
        width: '160px',
        position: 'fixed',
        zindex: 1,
        top: 0,
        left: 0,
        borderRadius: 0,
        border: '1px solid',
        borderColor: '#DDDDDD',
        overflowX: 'hidden',
        marginTop: 64,
    },
    menu: {
        display: 'flex',
        flexDirection: 'column',
    },
    menuItem: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-around',
        paddingTop: '10px',
        paddingBottom: '10px',
        borderBottom: '1px solid #D3D3D3'
    },
    h6: {
        lineHeight: 1,
    }


});
class Appbar extends Component {
    constructor(props) {
        super(props)

        this.state = {
            data: []
        };
        this.goBack = this.goBack.bind(this)
    };
    componentWillMount() {
        positionListData().then((result) => {
            console.log(result);
            let responseJson = result;
            console.log(responseJson);
            this.setState({
                data: responseJson.positionList
            })

        });
    }
    goBack() {
        window.history.back();
    }

    render() {
        const { classes } = this.props;
        return (
            <div className={classes.root}>
                <AppBar position="fixed" style={{ backgroundColor: '#064E5D' }}>
                    <Toolbar>
                        {
                            window.location.pathname === '/position' ?
                                <IconButton edge="start" color="inherit" aria-label="menu">
                                    <MenuIcon />
                                </IconButton> :
                                <IconButton edge="start" color="inherit" aria-label="menu" onClick={this.goBack}>
                                    <BackIcon />
                                </IconButton>

                        }


                    </Toolbar>
                </AppBar>
                {
                    window.location.pathname === '/position' ?
                        <div className={classes.rewired_sidemenu}>
                            <div className={classes.menu}>
                                {
                                    this.state.data.map(list =>
                                        <div className={classes.menuItem} key={list.key}>
                                            <Typography variant="subtitle2" className={classes.h6}>
                                                {list.value}
                                            </Typography>
                                            <ArrowForwardIos style={{ color: '#D3D3D3', fontSize: 16 }} />
                                        </div>
                                    )
                                }
                            </div>
                        </div> : null
                }
            </div>
        );
    }
}
Appbar.propTypes = {
    classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Appbar);

