import React, { Component } from 'react';
import './../App.css';
import ReactTable from 'react-table'
import 'react-table/react-table.css'
import Appbar from '../component/header/appbar';
import { candidate } from '../services/candidate';

class Position extends Component {
    constructor(props) {
        super(props)

        this.state = {
            post: []
        };
    };

    componentDidMount() {
        candidate().then((result) => {
            console.log(result);
            let candidates = result;
            this.setState({
                post: candidates.candidates
            })
        });
    }

    render() {
        const onRowClick = (state, rowInfo, instance) => {
            return {
                onClick: () => {
                    this.props.history.push(`/Position/${rowInfo.original.id}`)
                    // console.log('It was in this row:', rowInfo.original.id)
                }
            }
        }
        const columns = [
            {
                Header: "Date",
                accessor: "date",
                filterable: true
            },
            {
                id: 'name',
                Header: "Name",
                accessor: d => `${d.firstname} ${d.lastname}`,
                filterable: true,
                // getProps: (index) => {
                //     return {
                //         onClick: () => { this.onRowClick(index) },
                //     };
                // },
            },
            {
                Header: 'Email',
                accessor: 'email',
                filterable: true
            },
            {
                Header: 'Phone',
                accessor: 'phone',
                filterable: true
            },
            {
                Header: 'KSAs',
                accessor: 'ksa',
                filterable: true,
                id: "KSAs",
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "100") {
                        return row[filter.id] === "100%";
                    }
                    if (filter.value === "86") {
                        return row[filter.id] === "86%"
                    }
                },
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">All</option>
                        <option value="100">100%</option>
                        <option value="86">86%</option>
                    </select>
            },
            {
                Header: 'Employer Status',
                accessor: 'employerStatus',
                filterable: true,
                id: "employerStatus",
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "evaluated") {
                        return row[filter.id] === "Evaluated";
                    }
                },
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">All</option>
                        <option value="evaluated">Evaluated</option>
                    </select>
            },
            {
                Header: 'Review Status',
                accessor: 'review',
                filterable: true,
                id: "reviewStatus",
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "accepted") {
                        return row[filter.id] === "Accepted";
                    }
                },
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">All</option>
                        <option value="accepted">Accepted</option>
                    </select>
            },
            {
                Header: 'Status',
                accessor: 'active',
                filterable: true,
                id: "A_In_status",
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "active") {
                        return row[filter.id] === "Active";
                    }
                    if (filter.value === "inactive") {
                        return row[filter.id] === "In-Active";
                    }
                },
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">All</option>
                        <option value="active">Active</option>
                        <option value="inactive">Inactive</option>
                    </select>
            },
            {
                Header: 'Stage',
                accessor: 'status',
                filterable: true,
                getProps: (state, rowInfo) => {
                    if (rowInfo && rowInfo.row) {
                        return {
                            style: {
                                background:
                                    rowInfo.row.status === "Contacted" ? "blue" : "red",
                                color: "white"
                            }
                        };
                    } else {
                        return {};
                    }
                },
                // Cell: ({ value }) => (value >= 21 ? "Yes" : "No"),
                id: "status",
                filterMethod: (filter, row) => {
                    if (filter.value === "all") {
                        return true;
                    }
                    if (filter.value === "Accepted") {
                        return row[filter.id] === "Accepted";
                    }
                    if (filter.value === "Contacted") {
                        return row[filter.id] === "Contacted";
                    }
                    if (filter.value === "PendingDocs") {
                        return row[filter.id] === "Pending Docs";
                    }
                    if (filter.value === "DocsReceived") {
                        return row[filter.id] === "Docs Received";
                    }
                    if (filter.value === "InRoute") {
                        return row[filter.id] === "In Route";
                    }
                    if (filter.value === "InterviewScheduled") {
                        return row[filter.id] === "Interview Scheduled";
                    }
                    if (filter.value === "InterviewCompleted") {
                        return row[filter.id] === "Interview Completed";
                    }
                    if (filter.value === "Offered") {
                        return row[filter.id] === "Offered";
                    }
                    if (filter.value === "Hired") {
                        return row[filter.id] === "Hired";
                    }
                },
                Filter: ({ filter, onChange }) =>
                    <select
                        onChange={event => onChange(event.target.value)}
                        style={{ width: "100%" }}
                        value={filter ? filter.value : "all"}
                    >
                        <option value="all">All</option>
                        <option value="Accepted">Accepted</option>
                        <option value="Contacted">Contacted</option>
                        <option value="PendingDocs">Pending Docs</option>
                        <option value="DocsReceived">Docs Received</option>
                        <option value="InRoute">In Route</option>
                        <option value="InterviewScheduled">Interview Scheduled</option>
                        <option value="InterviewCompleted">Interview Completed</option>
                        <option value="Offered">Offered</option>
                        <option value="Hired">Hired</option>
                    </select>

            }
        ]
        return (
            <div>
                <Appbar name={'Position'} />
                <div className="container">
                    <ReactTable
                        columns={columns}
                        data={this.state.post}
                        defaultPageSize={25}
                        getTrProps={onRowClick}
                    >
                    </ReactTable>
                </div>
            </div>
        );
    }
}

export default Position;