import React from 'react';
import './App.css';
import Login from './component/login/login';
import { Route, BrowserRouter, Switch, Redirect } from 'react-router-dom'
import Position from './Position/position';
import Positiondetails from './Position/Position_Detail/position_detail';
function App() {
  return (

    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path={'/position'} component={Position} />
          <Route exact path={'/position/:id'} component={Positiondetails} />
          <Route exact path={'/'} component={Login} />
          <Route exact path={'/login'} component={Login} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
